/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import { lazy } from 'react';
import loadRemote from 'utils/mfeUtils';
import SystemAnnouncement from 'components/views/Settings/General/SystemAnnouncement';
import CreateSystemAnnouncement from 'components/views/Settings/General/SystemAnnouncement/CreateSystemAnnouncement';
import ParentInteraction from 'components/views/ParentInteraction';
import CentreDetailsPIM from 'components/views/ParentInteraction/CentreDetailsPIM/index';
import {
  VACANCY,
  FEE_MANAGEMENT,
  WAITLIST_MANAGEMENT,
  CENTRE_SETTINGS_MANAGEMENT,
  ADD_CENTRE,
  EDIT_CENTRE,
  ENQUIRIES_AND_APPLICATION,
  CREATE_PRE_ENROLMENT,
  CREATE_PRE_ENROLMENT_ECDAMVP,
  EDIT_PRE_ENROLMENT,
  EDIT_PRE_ENROLMENT_ECDAMVP,
  ENQUIRIES_DETAIL,
  WAITLIST_MANAGEMENT_DETAIL,
  WAITLIST_PARAM_TAGS,
  ADD_FEE_TIER,
  EDIT_FEE_TIER,
  NOT_FOUND,
  BLACKLIST_OVERVIEW,
  ADD_TO_BLACKLIST,
  SETTINGS,
  LEVEL_SETTINGS,
  ADD_LEVEL,
  EDIT_LEVEL,
  USER_MANAGEMENT,
  CREATE_ROLE,
  EDIT_ROLE,
  CREATE_USER,
  EDIT_USER,
  CLASS_DETAIL,
  CREATE_CLASS,
  CLASS_LISTING,
  CLASS_MANAGEMENT_CHILDREN_LISTING,
  ADD_CLASS_ACTIVITY_POST,
  ADD_CLASS_ACTIVITY_REPORT,
  CHILDREN_LISTING,
  ASSIGN_CHILDREN,
  CLASS_MANAGEMENT_ASSIGN_CHILDREN,
  SCHOOL_CONFIG,
  PROMOTE_CHILD,
  CLASS_MANAGEMENT_PROMOTE_CHILD,
  CHILD_DETAILS,
  CLASS_MANAGEMENT_CHILD_DETAILS,
  REGISTRATION_STATE_EDITOR,
  BUS_SETTINGS,
  EDIT_BUS,
  BUS_SETTINGS_BUS_DRIVER_EDIT,
  BUS_SETTINGS_BUS_DRIVER_ADD,
  HOLIDAY_MANAGEMENT,
  ADD_HOLIDAY,
  HOLIDAY_DETAILS,
  EVENT_LIST,
  EVENT_DETAILS,
  ADD_EVENT,
  EDIT_EVENT,
  EVENT_TYPE_LIST,
  CREATE_BROADCAST,
  EDIT_BROADCAST_DRAFT,
  FINANCE,
  TRANSFER_WITHDRAWAL_DASHBOARD,
  INVOICE_DETAILS,
  PRINCIPAL_DASHBOARD,
  CALENDAR,
  STAFF_ATTENDANCE,
  CREATE_DISCOUNT,
  DISCOUNT_SETTINGS,
  REGISTRATION_FORM_PREVIEW,
  DISCOUNT_DETAIL,
  CLASS,
  BUS_DETAIL,
  BUS_ADD,
  ENRICHMENT_CLASS_LIST,
  ENRICHMENT_VENDOR_VIEW,
  ENRICHMENT_CLASS_DETAIL,
  ENRICHMENT_CLASS_EDIT,
  DISEASE_MANAGEMENT,
  ENRICHMENT_ATTENDANCE_OVERVIEW,
  ENRICHMENT_CLASS_ATTENDANCE_SUMMARY,
  ENRICHMENT_CREATE_PROGRAMME,
  ENRICHMENT_CLASS_ATTENDANCE_SESSION,
  ENRICHMENT_OVERVIEW,
  ENRICHMENT_PROGRAM_DETAIL,
  ENRICHMENT_FINANCE,
  VISITOR_LOG,
  REPORTS,
  PRE_REGISTRATION_CHILD_PROFILE,
  PRE_REGISTRATION_ECDAMVP_CHILD_PROFILE,
  PORTFOLIOS_MANAGEMENT,
  PREPARE_PORTFOLIOS,
  REVIEW_PORTFOLIOS,
  TEACHER_DASHBOARD,
  ENQUIRIES_MANAGEMENT,
  ENQUIRY_DETAILS,
  WAITLIST_PARAMETERS,
  LISTING_VENDOR_TRAINERS,
  CREATE_VENDOR_TRAINER_USER,
  ENRICHMENT_PROGRAM_VENDOR_DETAIL,
  ENRICHMENT_CLASS_VENDOR_LIST,
  ENRICHMENT_CLASS_VENDOR_INFORMATION,
  ENRICHMENT_VENDOR_PROGRAMS_LIST,
  ENRICHMENT_GDS_REPORTS_LIST,
  MARKETING_CONSENT,
  GUARDIAN_LISTING,
  GUARDIAN_DETAILS,
  CENTRE_DETAIL,
  DRAFT_CENTRE_DETAIL,
  SYSTEM_ANNOUNCEMENT,
  CREATE_SYSTEM_ANNOUNCEMENT,
  SYSTEM_ANNOUNCEMENT_DETAILS,
  ACTIVITY_LOG,
  PARENT_INTERACTION,
  PARENT_INTERACTION_DETAILS,
  CENTRE_OPS_REPORTS,
  REGISTRATION_ECDAMVP,
  CARE_JOURNAL_WHITEBOARD,
  CARE_JOURNAL,
} from '../constants';
import commonProtectedRoutes from './common';
import DiscountDetail from '../../components/views/Settings/General/Discounts/DiscountDetail';
import BusListingMainComponent from '../../components/views/BusManagement/index';
import BusDetailsMainComponent from '../../components/views/BusManagement/BusDetails/index';
import BusEditMainComponent from '../../components/views/BusManagement/BusEdit/index';

const Admissions = lazy(() => import('../../components/views/Admissions'));
const AdmissionsECDAMVP = lazy(() =>
  import('../../components/views/AdmissionECDAMVP')
);
const CreatePreEnrolment = lazy(() =>
  import('../../components/views/Admissions/CreatePreEnrolment')
);

const CreatePreEnrolmentECDAMVP = lazy(() =>
  import('../../components/views/AdmissionECDAMVP/CreatePreEnrolment')
);
const VacancyPlanning = lazy(() =>
  import('../../components/views/EnrolmentPlanning')
);
const ActivityLog = lazy(() =>
  import('../../components/views/EnrolmentPlanning/ViewActivityLog')
);
const Settings = lazy(() => import('../../components/views/Settings'));
const LevelSettings = lazy(() =>
  import('../../components/views/Settings/LevelManagement')
);
const WaitlistParameters = lazy(() =>
  import('../../components/views/Settings/General/WaitlistParameters')
);
const LevelDetail = lazy(() =>
  import('../../components/views/Settings/LevelManagement/LevelDetail')
);
const DiseaseManagement = lazy(() =>
  import('../../components/views/DiseaseManagement')
);
const CreateBusRoute = lazy(() =>
  import('../../components/views/BusManagement/CreateBusRoute')
);
const BusDriverEdit = lazy(() =>
  import('../../components/views/BusManagement/BusDriver/BusDriverEdit')
);
const CentreSettings = lazy(() =>
  import('../../components/views/Settings/CentreManagement')
);
const ViewEditCentre = lazy(() =>
  import('../../components/views/Settings/CentreManagement/Centre')
);
const ViewEditDraftCentre = lazy(() =>
  import('../../components/views/Settings/CentreManagement/Centre/Draft')
);
const AddCentre = lazy(() =>
  import('../../components/views/Settings/CentreManagement/Centre/CentreDetail')
);

const WaitlistParamTags = lazy(() =>
  import('../../components/views/Settings/General/WaitlistParamTags')
);
const SchoolConfig = lazy(() =>
  import('../../components/views/Settings/General/SchoolConfig')
);
const WaitListOverview = lazy(() => import('../../components/views/WaitList'));
const BlackList = lazy(() => import('../../components/views/BlackList'));
const AddToBlackList = lazy(() =>
  import('../../components/views/BlackList/AddToBlackList')
);
const UserManagement = lazy(() =>
  import('../../components/views/Settings/UserAndRoleManagement')
);
const AddEditRole = lazy(() =>
  import(
    '../../components/views/Settings/UserAndRoleManagement/Role/AddEditRole'
  )
);
const AddEditUser = lazy(() =>
  import(
    '../../components/views/Settings/UserAndRoleManagement/User/AddEditUser'
  )
);
const ListVendorTrainers = lazy(() =>
  import('../../components/views/EnrichmentProgramme/Vendor/ListVendorTrainers')
);
const CreateVendorTrainerUser = lazy(() =>
  import(
    '../../components/views/EnrichmentProgramme/Vendor/CreateVendorTrainerUser'
  )
);
const FeeManagement = lazy(() =>
  import('../../components/views/FeeManagement')
);
const AddEditFeeTier = lazy(() =>
  import('../../components/views/FeeManagement/AddEditFeeTier')
);
const ClassDetail = lazy(() =>
  import('../../components/views/ClassManagement/ClassDetails')
);
const CreateNewClass = lazy(() =>
  import('../../components/views/ClassManagement/CreateNewClass')
);
const ClassListing = lazy(() =>
  import('../../components/views/ClassManagement')
);
const ChildrenListing = lazy(() =>
  import('../../components/views/ClassManagement/ChildrenListing')
);
const AssignChildren = lazy(() =>
  import(
    '../../components/views/ClassManagement/ChildrenManagement/AssignChildrenClass'
  )
);
const PromoteChild = lazy(() =>
  import(
    '../../components/views/ClassManagement/ChildrenManagement/PromoteChildren'
  )
);
const ChildDetails = lazy(() =>
  import('../../components/views/ClassManagement/ChildDetails')
);
const GuardianListing = lazy(() =>
  import('../../components/views/ClassManagement/GuardianMasterList')
);
const GuardianDetails = lazy(() =>
  import(
    '../../components/views/ClassManagement/GuardianMasterList/GuardianDetails'
  )
);
const RegistrationState = lazy(() =>
  import('../../components/views/Settings/RegistrationState')
);
const HolidayManagement = lazy(() =>
  import('../../components/views/Settings/HolidayManagement')
);
const HolidayDetails = lazy(() =>
  import('../../components/views/Settings/HolidayManagement/Details')
);
const AddHoliday = lazy(() =>
  import('../../components/views/Settings/HolidayManagement/Form')
);
const EventForm = lazy(() =>
  import('../../components/views/EventManagement/Form')
);
const BroadCastForm = lazy(() =>
  import('../../components/views/Broadcast/Form')
);
const EventList = lazy(() => import('../../components/views/EventManagement'));
const EventDetails = lazy(() =>
  import('../../components/views/EventManagement/EventDetails')
);
const AllEvents = lazy(() =>
  import('../../components/views/EventManagement/AllEventList')
);
const InvoiceOrRecieptOverview = lazy(() =>
  import('../../components/views/Finance')
);
const TransferWithdrawalDashboard = lazy(() =>
  import('../../components/views/TransferAndWithdrawals')
);
const InvoiceDetails = lazy(() =>
  import('../../components/views/Finance/InvoiceOverview')
);
const PrincipalDashboard = lazy(() =>
  import('../../components/views/Dashboard/PrincipalDashboard')
);
const TeacherDashboard = lazy(() =>
  import('../../components/views/Dashboard/TeacherDashboard')
);
const Calendar = lazy(() => import('../../components/views/Calendar'));
const StaffAttendance = lazy(() =>
  import('../../components/views/StaffAttendance')
);
const CreateDiscount = lazy(() =>
  import('../../components/views/Settings/General/Discounts/CreateDiscount')
);
const Discount = lazy(() =>
  import('../../components/views/Settings/General/Discounts')
);
const RegistrationFormPreview = lazy(() =>
  import(
    '../../components/views/ClassManagement/ChildDetails/Document/RegistrationForm'
  )
);
const ClassVendorList = lazy(() =>
  import(
    '../../components/views/EnrichmentProgramme/Classes/ClassVendor/ClassVendorList'
  )
);
const GDSReportsList = lazy(() =>
  import('../../components/views/EnrichmentProgramme/Reports/index')
);
const Class = lazy(() => import('../../components/views/Class/Class'));
const EnrichmentClassListMainComponent = lazy(() =>
  import('../../components/views/EnrichmentProgramme/Classes/ClassList/index')
);
const EnrichmentVendorListMainComponent = lazy(() =>
  import('../../components/views/EnrichmentProgramme/Classes/Vendor/index')
);
const EnrichmentCreatePrograme = lazy(() =>
  import('../../components/views/EnrichmentProgramme/CreateNewProgramme/index')
);
const ClassInformationTabWise = lazy(() =>
  import('../../components/views/EnrichmentProgramme/Classes/ClassInformation')
);
const EnrichmentAttendanceOverviewComponent = lazy(() =>
  import('../../components/views/EnrichmentProgramme/Attendance')
);
const EnrichmentAttendanceSummaryComponent = lazy(() =>
  import('../../components/views/EnrichmentProgramme/Attendance/Summary/index')
);
const EnrichmentAttendanceSessionComponent = lazy(() =>
  import('../../components/views/EnrichmentProgramme/Attendance/Session')
);
const FinanceOverview = lazy(() =>
  import('../../components/views/EnrichmentProgramme/Finance/index')
);
const EPOverview = lazy(() =>
  import('../../components/views/EnrichmentProgramme/overview/index')
);
const EnrichementProgramDetail = lazy(() =>
  import('../../components/views/EnrichmentProgramme/ProgramDetail/index')
);
const EnrichementProgramVendorDetail = lazy(() =>
  import('../../components/views/EnrichmentProgramme/ProgramDetail/Vendor')
);
const EnrichmentVendorProgramsList = lazy(() =>
  import('../../components/views/EnrichmentProgramme/overview/Vendor')
);
const VisitorLogComponent = lazy(() =>
  import('../../components/views/VisitorLogs')
);
const ReportsComponent = lazy(() => import('../../components/views/Reports'));
const CreatePost = lazy(() =>
  import('../../components/views/ClassManagement/ClassActivity/Create')
);
const ClassVendorInformationTab = lazy(() =>
  import(
    '../../components/views/EnrichmentProgramme/Classes/ClassVendor/ClassVendorInformation'
  )
);
// const PreRegistrationChildDetails = lazy(() =>
//   import('../../components/views/Admissions/ChildDetails/index')
// );

const PreRegistrationChildDetails = lazy(() =>
  import('../../components/views/RegistrationV2')
);

const PreRegistrationChildDetailsECDAMVP = lazy(() =>
  import('../../components/views/RegistrationECDAMVP')
);

const PortfoliosManagement = lazy(() =>
  import('../../components/views/PortfoliosManagement/index')
);

const EnquiriesManagement = lazy(() =>
  import('../../components/views/EnquiriesManagement')
);

const EnquiryDetails = lazy(() =>
  import('../../components/views/EnquiriesManagement/EnquiryDetails')
);

const PreparePortfoliosManagement = lazy(() =>
  import('../../components/views/PortfoliosManagement/Portfolios/Create/index')
);

const ReviewPortfolio = lazy(() =>
  import('../../components/views/PortfoliosManagement/Portfolios/Review/index')
);

const MarketingConsent = lazy(() =>
  import('../../components/views/MarketingConsent/index')
);

const CentreOpsReports = lazy(() =>
  import('../../components/views/CentreOpsReports/index')
);

const childRoutes = [
  CHILD_DETAILS,
  ENQUIRIES_DETAIL,
  WAITLIST_MANAGEMENT_DETAIL,
  CLASS_MANAGEMENT_CHILD_DETAILS,
];

const staffRoutes = [
  ...commonProtectedRoutes,
  ...childRoutes.map(eachRoute => ({
    url: eachRoute,
    component: {
      default: ChildDetails,
    },
  })),
  {
    url: VACANCY,
    component: {
      default: VacancyPlanning,
    },
  },
  {
    url: ACTIVITY_LOG,
    component: {
      default: ActivityLog,
    },
  },
  {
    url: GUARDIAN_LISTING,
    component: {
      default: GuardianListing,
    },
  },
  {
    url: GUARDIAN_DETAILS,
    component: {
      default: GuardianDetails,
    },
  },
  {
    url: ENQUIRIES_AND_APPLICATION,
    component: {
      default: Admissions,
    },
  },
  {
    url: REGISTRATION_ECDAMVP,
    component: {
      default: AdmissionsECDAMVP,
    },
  },
  {
    url: CREATE_PRE_ENROLMENT,
    component: {
      default: CreatePreEnrolment,
    },
  },

  {
    url: CREATE_PRE_ENROLMENT_ECDAMVP,
    component: {
      default: CreatePreEnrolmentECDAMVP,
    },
  },

  {
    url: EDIT_PRE_ENROLMENT,
    component: {
      default: CreatePreEnrolment,
    },
  },

  {
    url: EDIT_PRE_ENROLMENT_ECDAMVP,
    component: {
      default: CreatePreEnrolmentECDAMVP,
    },
  },
  {
    url: PRE_REGISTRATION_CHILD_PROFILE,
    component: {
      default: PreRegistrationChildDetails,
    },
  },
  {
    url: PRE_REGISTRATION_ECDAMVP_CHILD_PROFILE,
    component: {
      default: PreRegistrationChildDetailsECDAMVP,
    },
  },

  {
    url: WAITLIST_PARAM_TAGS,
    component: {
      default: WaitlistParamTags,
    },
  },
  {
    url: WAITLIST_MANAGEMENT,
    component: {
      default: WaitListOverview,
    },
  },
  {
    url: BLACKLIST_OVERVIEW,
    component: {
      default: BlackList,
    },
  },
  {
    url: ADD_TO_BLACKLIST,
    component: {
      default: AddToBlackList,
    },
  },
  {
    url: SETTINGS,
    component: {
      default: Settings,
    },
  },

  {
    url: WAITLIST_PARAMETERS,
    component: {
      default: WaitlistParameters,
    },
  },
  {
    url: LEVEL_SETTINGS,
    component: {
      default: LevelSettings,
    },
  },
  {
    url: ADD_LEVEL,
    component: {
      default: LevelDetail,
    },
  },
  {
    url: EDIT_LEVEL,
    component: {
      default: LevelDetail,
    },
  },
  {
    url: DISEASE_MANAGEMENT,
    component: {
      default: DiseaseManagement,
    },
  },
  {
    url: BUS_SETTINGS,
    component: {
      default: BusListingMainComponent,
    },
  },
  {
    url: BUS_ADD,
    component: {
      default: CreateBusRoute,
    },
  },
  {
    url: BUS_DETAIL,
    component: {
      default: BusDetailsMainComponent,
    },
  },
  {
    url: EDIT_BUS,
    component: {
      default: BusEditMainComponent,
    },
  },
  {
    url: BUS_SETTINGS_BUS_DRIVER_EDIT,
    component: {
      default: BusDriverEdit,
    },
  },
  {
    url: BUS_SETTINGS_BUS_DRIVER_ADD,
    component: {
      default: BusDriverEdit,
    },
  },
  {
    url: DISCOUNT_DETAIL,
    component: {
      default: DiscountDetail,
    },
  },
  {
    url: CENTRE_SETTINGS_MANAGEMENT,
    component: {
      default: CentreSettings,
    },
  },
  {
    url: ADD_CENTRE,
    component: {
      default: AddCentre,
    },
  },
  {
    url: EDIT_CENTRE,
    component: {
      default: ViewEditCentre,
    },
  },
  {
    url: CENTRE_DETAIL,
    component: {
      default: ViewEditCentre,
    },
  },
  {
    url: DRAFT_CENTRE_DETAIL,
    component: {
      default: ViewEditDraftCentre,
    },
  },
  {
    url: FEE_MANAGEMENT,
    component: {
      default: FeeManagement,
    },
  },
  {
    url: CREATE_CLASS,
    component: {
      default: CreateNewClass,
    },
  },
  {
    url: CLASS_LISTING,
    component: {
      default: ClassListing,
    },
  },
  {
    url: USER_MANAGEMENT,
    component: {
      default: UserManagement,
    },
  },
  {
    url: CREATE_ROLE,
    component: {
      default: AddEditRole,
    },
  },
  {
    url: EDIT_ROLE,
    component: {
      default: AddEditRole,
    },
  },
  {
    url: CREATE_USER,
    component: {
      default: AddEditUser,
    },
  },
  {
    url: EDIT_USER,
    component: {
      default: AddEditUser,
    },
  },
  {
    url: CLASS_DETAIL,
    component: {
      default: ClassDetail,
    },
  },
  {
    url: EDIT_FEE_TIER,
    component: {
      default: AddEditFeeTier,
    },
  },
  {
    url: ADD_FEE_TIER,
    component: {
      default: AddEditFeeTier,
    },
  },
  {
    url: CHILDREN_LISTING,
    component: {
      default: ChildrenListing,
    },
  },
  {
    url: CLASS_MANAGEMENT_CHILDREN_LISTING,
    component: {
      default: ChildrenListing,
    },
  },
  {
    url: ADD_CLASS_ACTIVITY_POST,
    component: {
      default: CreatePost,
    },
  },
  {
    url: ADD_CLASS_ACTIVITY_REPORT,
    component: {
      default: CreatePost,
    },
  },
  {
    url: ASSIGN_CHILDREN,
    component: {
      default: AssignChildren,
    },
  },
  {
    url: CLASS_MANAGEMENT_ASSIGN_CHILDREN,
    component: {
      default: AssignChildren,
    },
  },
  {
    url: PROMOTE_CHILD,
    component: {
      default: PromoteChild,
    },
  },
  {
    url: CLASS_MANAGEMENT_PROMOTE_CHILD,
    component: {
      default: PromoteChild,
    },
  },
  {
    url: SCHOOL_CONFIG,
    component: {
      default: SchoolConfig,
    },
  },
  {
    url: HOLIDAY_MANAGEMENT,
    component: {
      default: HolidayManagement,
    },
  },
  {
    url: ADD_HOLIDAY,
    component: {
      default: AddHoliday,
    },
  },
  {
    url: HOLIDAY_DETAILS,
    component: {
      default: HolidayDetails,
    },
  },
  {
    url: REGISTRATION_STATE_EDITOR,
    component: {
      default: RegistrationState,
    },
  },
  {
    url: ADD_EVENT,
    component: {
      default: EventForm,
    },
  },
  {
    url: EDIT_EVENT,
    component: {
      default: EventForm,
    },
  },
  {
    url: CREATE_BROADCAST,
    component: {
      default: BroadCastForm,
    },
  },
  {
    url: EDIT_BROADCAST_DRAFT,
    component: {
      default: BroadCastForm,
    },
  },
  {
    url: EVENT_LIST,
    component: {
      default: EventList,
    },
  },
  {
    url: EVENT_TYPE_LIST,
    component: {
      default: AllEvents,
    },
  },
  {
    url: EVENT_DETAILS,
    component: {
      default: EventDetails,
    },
  },
  {
    url: PRINCIPAL_DASHBOARD,
    component: {
      default: PrincipalDashboard,
    },
  },
  {
    url: TEACHER_DASHBOARD,
    component: {
      default: TeacherDashboard,
    },
  },
  {
    url: CALENDAR,
    component: {
      default: Calendar,
    },
  },
  {
    url: NOT_FOUND,
    component: {
      default: NOT_FOUND,
    },
  },
  {
    url: FINANCE,
    component: {
      default: InvoiceOrRecieptOverview,
    },
  },
  {
    url: TRANSFER_WITHDRAWAL_DASHBOARD,
    component: {
      default: TransferWithdrawalDashboard,
    },
  },
  {
    url: INVOICE_DETAILS,
    component: {
      default: InvoiceDetails,
    },
  },
  {
    url: STAFF_ATTENDANCE,
    component: {
      default: StaffAttendance,
    },
  },
  {
    url: CREATE_DISCOUNT,
    component: {
      default: CreateDiscount,
    },
  },
  {
    url: REGISTRATION_FORM_PREVIEW,
    component: {
      default: RegistrationFormPreview,
    },
  },
  {
    url: DISCOUNT_SETTINGS,
    component: {
      default: Discount,
    },
  },
  {
    url: DISCOUNT_SETTINGS,
    component: {
      default: Discount,
    },
  },
  {
    url: CLASS,
    component: {
      default: Class,
    },
  },
  {
    url: ENRICHMENT_CLASS_LIST,
    component: {
      default: EnrichmentClassListMainComponent,
    },
  },
  {
    url: ENRICHMENT_VENDOR_VIEW,
    component: {
      default: EnrichmentVendorListMainComponent,
    },
  },
  {
    url: ENRICHMENT_PROGRAM_VENDOR_DETAIL,
    component: {
      default: EnrichementProgramVendorDetail,
    },
  },
  {
    url: ENRICHMENT_VENDOR_PROGRAMS_LIST,
    component: {
      default: EnrichmentVendorProgramsList,
    },
  },
  {
    url: ENRICHMENT_CLASS_DETAIL,
    component: {
      default: ClassInformationTabWise,
    },
  },
  {
    url: ENRICHMENT_CLASS_EDIT,
    component: {
      default: ClassInformationTabWise,
    },
  },
  {
    url: ENRICHMENT_ATTENDANCE_OVERVIEW,
    component: {
      default: EnrichmentAttendanceOverviewComponent,
    },
  },
  {
    url: ENRICHMENT_CLASS_ATTENDANCE_SUMMARY,
    component: {
      default: EnrichmentAttendanceSummaryComponent,
    },
  },
  {
    url: ENRICHMENT_CREATE_PROGRAMME,
    component: {
      default: EnrichmentCreatePrograme,
    },
  },
  {
    url: ENRICHMENT_OVERVIEW,
    component: {
      default: EPOverview,
    },
  },
  {
    url: ENRICHMENT_FINANCE,
    component: {
      default: FinanceOverview,
    },
  },
  {
    url: ENRICHMENT_PROGRAM_DETAIL,
    component: {
      default: EnrichementProgramDetail,
    },
  },
  {
    url: ENRICHMENT_CLASS_ATTENDANCE_SESSION,
    component: {
      default: EnrichmentAttendanceSessionComponent,
    },
  },
  {
    url: ENRICHMENT_CLASS_VENDOR_INFORMATION,
    component: {
      default: ClassVendorInformationTab,
    },
  },
  {
    url: VISITOR_LOG,
    component: {
      default: VisitorLogComponent,
    },
  },
  {
    url: REPORTS,
    component: {
      default: ReportsComponent,
    },
  },
  {
    url: PORTFOLIOS_MANAGEMENT,
    component: {
      default: PortfoliosManagement,
    },
  },
  {
    url: PREPARE_PORTFOLIOS,
    component: {
      default: PreparePortfoliosManagement,
    },
  },
  {
    url: REVIEW_PORTFOLIOS,
    component: {
      default: ReviewPortfolio,
    },
  },
  {
    url: ENQUIRIES_MANAGEMENT,
    component: {
      default: EnquiriesManagement,
    },
  },
  {
    url: ENQUIRY_DETAILS,
    component: {
      default: EnquiryDetails,
    },
  },
  {
    url: LISTING_VENDOR_TRAINERS,
    component: {
      default: ListVendorTrainers,
    },
  },
  {
    url: CREATE_VENDOR_TRAINER_USER,
    component: {
      default: CreateVendorTrainerUser,
    },
  },
  {
    url: ENRICHMENT_CLASS_VENDOR_LIST,
    component: {
      default: ClassVendorList,
    },
  },
  {
    url: ENRICHMENT_GDS_REPORTS_LIST,
    component: {
      default: GDSReportsList,
    },
  },
  {
    url: MARKETING_CONSENT,
    component: {
      default: MarketingConsent,
    },
  },
  {
    url: SYSTEM_ANNOUNCEMENT,
    component: {
      default: SystemAnnouncement,
    },
  },
  {
    url: CREATE_SYSTEM_ANNOUNCEMENT,
    component: {
      default: CreateSystemAnnouncement,
    },
  },
  {
    url: SYSTEM_ANNOUNCEMENT_DETAILS,
    component: {
      default: CreateSystemAnnouncement,
    },
  },
  {
    url: PARENT_INTERACTION,
    component: {
      default: ParentInteraction,
    },
  },
  {
    url: PARENT_INTERACTION_DETAILS,
    component: {
      default: CentreDetailsPIM,
    },
  },
  {
    url: CENTRE_OPS_REPORTS,
    component: {
      default: CentreOpsReports,
    },
  },
  {
    url: CARE_JOURNAL_WHITEBOARD,
    component: {
      default: loadRemote({
        module: 'WhiteboardCareJournal',
        loadModule: () => import('WhiteboardCareJournal/App'),
      }),
    },
  },
  {
    url: CARE_JOURNAL,
    component: {
      default: loadRemote({
        module: 'SNCareJournal',
        loadModule: () => import('SNCareJournal/App'),
      }),
    },
  },
];

export default staffRoutes;
