import {
  SplitSdk,
  SplitFactory,
  SplitClient,
} from '@splitsoftware/splitio-react';
import React, { useEffect, useMemo, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useSelector } from 'react-redux';
import { get, uniq } from 'lodash';
// eslint-disable-next-line import/no-cycle
import { extractLevelIdsByRoleForSplit } from 'utils/CareJournalUtils';
import { getLevelsByTeacherId } from './utils/ms-utils/classService';

export default class SplitReactSDK {
  static key = 'anonymous';

  static _factory;

  static config = {
    core: {
      authorizationKey: process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY,
      key: this.key,
    },
    impressionListener: {
      logImpression(data) {
        datadogRum.addFeatureFlagEvaluation(
          data.impression.feature,
          data.impression.treatment
        );
      },
    },
  };

  static get client() {
    return this._factory?.client?.(this.key);
  }

  static init({ debug = false, streamingEnabled = false } = {}) {
    this.config = { ...this.config, debug, streamingEnabled };
    this._factory = SplitSdk(this.config);
  }

  static setUserId(userId) {
    this.key = userId;
  }
}

export const SplitReact = ({ children }) => {
  const user = useSelector(state => get(state, 'userDetails.data', {}));
  const isMFS = useSelector(state => get(state, 'isMFS', false));
  const role = get(user, 'userRoleRelations.data[0].role.name', '');
  const userId = get(user, 'ID', 'anonymous');
  SplitReactSDK.setUserId(userId);

  const [levels, setLevels] = useState([]);
  const centreIds = useMemo(() => {
    const centres = uniq(
      (get(user, 'userAccessControls.data') || [])
        .map(c => get(c, 'centre.ID'))
        .filter(id => id)
    );
    if (centres.length === 0) return ['all'];
    return centres.map(d => d.toString());
  }, [user]);

  const levelIds = extractLevelIdsByRoleForSplit(role, levels, isMFS);

  const attributes = {
    platform: 'sn-frontend',
    email: get(user, 'email', ''),
    role,
    isStaff: get(user, 'userRoleRelations.data[0].role.isStaff', false),
    centreIds,
    levelIds,
  };

  useEffect(() => {
    if (user?.ID) getLevelsByTeacherId(user?.ID).then(res => setLevels(res));
  }, [user?.ID]);

  return (
    <SplitFactory
      factory={SplitReactSDK._factory}
      updateOnSdkReady
      updateOnSdkTimedout
      updateOnSdkUpdate
    >
      <SplitClient splitKey={userId} attributes={attributes}>
        {children}
      </SplitClient>
    </SplitFactory>
  );
};
