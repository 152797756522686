/* Authenticated routes */

export const OVERVIEW = '/overview';
export const ENQUIRIES_AND_APPLICATION = '/registrations';
export const REGISTRATION_ECDAMVP = '/ecda-registrations';
export const ENROLMENT = '/overview/enrolment/:id';
export const ENROLMENT_ECDA = '/overview/ecda-enrolment/:id';
export const ENROLMENT_PAYMENT = '/overview/enrolment/:id/payment';
export const ENROLMENT_PAYMENT_ECDA = '/overview/ecda-enrolment/:id/payment';
export const PARENT_CREATE_ENROLMENT = '/overview/enrolment';
export const CREATE_PRE_ENROLMENT = '/registrations/create';
export const CREATE_PRE_ENROLMENT_ECDAMVP = '/ecda-registrations/create';
export const EDIT_PRE_ENROLMENT = '/registrations/edit';
export const EDIT_PRE_ENROLMENT_ECDAMVP = '/ecda-registrations/edit';
/** * Marketing Consent  ** */
export const MARKETING_CONSENT = '/marketingconsent';
/** *  -------  ** */

/** * Waitlist Management ** */
export const WAITLIST_MANAGEMENT = '/waitlistmanagement';
export const WAITLIST_PARAM_TAGS = '/settings/waitlisttags';
/** *  -------  ** */

/** * Fee Management ** */
export const FEE_MANAGEMENT = '/feemanagement';
export const EDIT_FEE_TIER = '/feemanagement/editfeetier';
export const ADD_FEE_TIER = '/feemanagement/addfeetier';
/** *  -------  ** */

/** * Special Handling ** */
export const BLACKLIST_OVERVIEW = '/specialmanagement';
export const ADD_TO_BLACKLIST = '/specialmanagement/addtospecialhandling';
/** *  -------  ** */

/** * Vacancy Planning ** */
export const VACANCY = '/enrollmentplanning';
export const ACTIVITY_LOG = '/enrollmentplanning/activitylog';
export const PENDING_LATE_PICKUP =
  '/class?statusFilter=unverified_late_pick_up';
/** *  -------  ** */

/** * Disease Management ** */
export const DISEASE_MANAGEMENT = '/diseasemanagement';

/** * Bus Management ** */
export const BUS_SETTINGS = '/busmanagement';
export const BUS_ADD = '/busmanagement/bus/add';
export const BUS_DETAIL = '/busmanagement/bus/view/:id';
export const EDIT_BUS = '/busmanagement/bus/edit/:id';
export const BUS_SETTINGS_BUS_DRIVER_EDIT = '/busmanagement/busdriver/edit/:id';
export const BUS_SETTINGS_BUS_DRIVER_ADD = '/busmanagement/busdriver/add';
/** *  -------  ** */

/** * User & Role Management ** */
export const USER_MANAGEMENT = '/settings/usermanagement';
export const CREATE_ROLE = '/settings/usermanagement/createrole';
export const EDIT_ROLE = '/settings/usermanagement/editrole/:id';
export const CREATE_USER = '/settings/usermanagement/createuser';
export const EDIT_USER = '/settings/usermanagement/edituser/:id';
/** *  -------  ** */

/** * Class Management + Child Promote/Transfer/Withdrawal ** */
export const CLASS_DETAIL = '/classmanagement/details/:id';
export const CREATE_CLASS = '/classmanagement/createclass';
export const CLASS_LISTING = '/classmanagement';
export const CHILDREN_LISTING = '/childrenmasterlist';
export const CLASS_MANAGEMENT_CHILDREN_LISTING =
  '/classmanagement/childrenlist';
export const CLASS_MANAGEMENT_ASSIGN_CHILDREN =
  '/classmanagement/childrenlist/assignchildren';
export const ASSIGN_CHILDREN = '/childrenmasterlist/assignchildren';
export const CLASS_MANAGEMENT_PROMOTE_CHILD =
  '/classmanagement/childrenlist/promotechild';
export const PROMOTE_CHILD = '/childrenmasterlist/promotechild';
export const ADD_CLASS_ACTIVITY_POST =
  '/classmanagement/details/:id/createpost/:type/:postid?/:accessType?';
export const ADD_CLASS_ACTIVITY_REPORT =
  '/reportmanagement/:id/createpost/:type/:postid?';
/** *  -------  ** */

/** * Settings ** */
export const SETTINGS = '/settings';
export const LEVEL_SETTINGS = '/settings/levelmanagement';
export const WAITLIST_PARAMETERS = '/settings/waitlistparameters';
export const ADD_LEVEL = '/settings/levelmanagement/add';
export const EDIT_LEVEL = '/settings/levelmanagement/edit/:id';
export const SCHOOL_CONFIG = '/settings/schoolconfig';
export const REGISTRATION_STATE_EDITOR = '/registrationstateeditor';
export const CENTRE_SETTINGS_MANAGEMENT = '/settings/centremanagement';
export const ADD_CENTRE = '/settings/centremanagement/add';
export const CENTRE_DETAIL = '/settings/centremanagement/:id';
export const DRAFT_CENTRE_DETAIL = '/settings/centremanagement/draft/:id';
export const EDIT_CENTRE = '/settings/centremanagement/edit/:id';
export const HOLIDAY_MANAGEMENT = '/settings/holidaymanagement';
export const ADD_HOLIDAY = '/settings/holidaymanagement/add';
export const HOLIDAY_DETAILS = '/settings/holidaymanagement/:id';
export const DISCOUNT_DETAIL = '/settings/discount/view/:id';
export const SYSTEM_ANNOUNCEMENT_DETAILS = '/settings/systemannouncements/:id';

/** * Vendor trainers ** */
export const LISTING_VENDOR_TRAINERS = '/enrichment/vendor/users';
export const CREATE_VENDOR_TRAINER_USER = '/enrichment/vendor/createuser';

/** *  -------  ** */

/** *  Settings - Discounts ** */
export const DISCOUNT_SETTINGS = '/settings/discount';
export const CREATE_DISCOUNT = '/settings/discount/create';
/** *  -------  ** */

/** *  Settings - System Announcement ** */
export const SYSTEM_ANNOUNCEMENT = '/settings/systemannouncements';
export const CREATE_SYSTEM_ANNOUNCEMENT =
  '/settings/systemannouncements/create';
/** *  -------  ** */

/** * Broadcasting ** */
export const BROADCAST = '/broadcast';
export const CREATE_BROADCAST = '/broadcast/create';
export const EDIT_BROADCAST_DRAFT = '/broadcast/edit/:id';
/** *  -------  ** */

/** * Event Management ** */
export const ADD_EVENT = '/eventmanagement/create';
export const EDIT_EVENT = '/eventmanagement/edit/:id/:forceEdit';
export const EVENT_LIST = '/eventmanagement';
export const EVENT_TYPE_LIST = '/eventmanagement/:type';
export const EVENT_DETAILS = '/eventmanagement/details/:id';
/** *  -------  ** */

/** * Principal Calendar ** */
export const CALENDAR = '/calendar';
/** *  -------  ** */

/** * Finance ** */
export const FINANCE = '/financemanagement';
export const INVOICE_DETAILS = '/finance/invoice/:id';
/** *  -------  ** */

/** * Transfer&Withdrawals ** */
export const TRANSFER_WITHDRAWAL_DASHBOARD = '/transferwithdrawals';
/** *  -------  ** */

/** * Principal Dashboard ** */
export const PRINCIPAL_DASHBOARD = '/dashboard';
/** *  -------  ** */

/** * Teacher Dashboard ** */
export const TEACHER_DASHBOARD = '/dashboard';
/** *  -------  ** */

/** * Staff Attendance ** */
export const STAFF_ATTENDANCE = '/attendance';
/** *  -------  ** */

/** * Child Details ** */
export const ENQUIRIES_DETAIL = '/registrations/child/:id/:tab?';

export const CHILD_DETAILS = '/childrenmasterlist/details/:id/:tab?';

export const WAITLIST_MANAGEMENT_DETAIL = '/waitlistmanagement/:id/:tab?';

export const ENQUIRIES_MANAGEMENT = '/enquiriesmanagement';

export const ENQUIRY_DETAILS = '/enquiriesmanagement/details/:id';

export const CLASS_MANAGEMENT_CHILD_DETAILS =
  '/classmanagement/details/child/:id/:tab?';

export const REGISTRATION_FORM_PREVIEW =
  '/registrations/registrationform/:enquiryid';

/** *  -------  ** */

/** * Main Class Attendance ** */
export const CLASS = '/class';
/** *  -------  ** */

/** * Guardian Details ** */
export const GUARDIAN_LISTING = '/guardianmasterlist';
export const GUARDIAN_DETAILS = '/guardianmasterlist/details/:id';
/** * ------ ** */

/** * Centre Admin -> Visitor Log ** */
export const VISITOR_LOG = '/visitorlog';
export const REPORTS = '/reports';
/** *  -------  ** */

/** * Portfolios Management -> Observation&Protofolio need to change Be change ** */
export const PORTFOLIOS_MANAGEMENT = '/reportmanagement';
export const PREPARE_PORTFOLIOS =
  '/reportmanagement/prepareportofolio/:childId/:levelId?/:classId?/:id?';
export const REVIEW_PORTFOLIOS = `${PORTFOLIOS_MANAGEMENT}/:id/:classId?/:isEnrolled?/review`;
/** *  -------  ** */

/** * Enrichment Programme ** */
export const ENRICHMENT_CLASS_LIST = '/enrichment/classes';
export const ENRICHMENT_VENDOR_VIEW = '/enrichment/classes/vendor';
export const ENRICHMENT_CLASS_DETAIL = '/enrichment/classes/detail/:id';
export const ENRICHMENT_CLASS_EDIT = '/enrichment/classes/edit/:id';
export const ENRICHMENT_ATTENDANCE_OVERVIEW = '/enrichment/attendance';
export const ENRICHMENT_CLASS_ATTENDANCE_SUMMARY =
  '/enrichment/attendance/:eclassid';
export const ENRICHMENT_CREATE_PROGRAMME = '/enrichment/programs/create';
export const ENRICHMENT_OVERVIEW = '/enrichment/programs';
export const ENRICHMENT_FINANCE = '/enrichment/finance';
export const ENRICHMENT_PROGRAM_DETAIL = '/enrichment/programs/detail/:id';
export const ENRICHMENT_CLASS_ATTENDANCE_SESSION =
  '/enrichment/attendance/session/:id/:occurrenceID';
export const ENRICHMENT_PROGRAM_VENDOR_DETAIL =
  '/enrichment/vendorprograms/detail/:id';
export const ENRICHMENT_CLASS_VENDOR_LIST = '/enrichment/myclasses';
export const ENRICHMENT_GDS_REPORTS_LIST = '/enrichment/ep-reports';
export const ENRICHMENT_VENDOR_PROGRAMS_LIST = '/enrichment/vendorprograms';
export const ENRICHMENT_VENDOR_CLASS_DETAIL =
  '/enrichment/vendorprograms/detail/:id';
export const ENRICHMENT_MY_CLASS_LIST = '/enrichment/myclasses';
export const ENRICHMENT_CLASS_VENDOR_INFORMATION =
  '/enrichment/myclasses/vendor/detail/:id';
/** *  -------  ** */

/** * Enrichment Programme - Parent Portal** */
export const ENRICHMENT_PROGRAM_LIST = '/enrichment/programs';
export const ENRICHMENT_PARENT_CLASS_DETAIL =
  '/enrichment/programs/info/:id/:programmeID';
export const PAYMENT_DETAILS = '/enrichment/payment';
export const ENRICHMENT_MY_CLASS_DETAIL = '/enrichment/myclasses/detail/:id';
/** *  -------  ** */

/** * Pre Registration - Staff Portal**  */
export const PRE_REGISTRATION_CHILD_PROFILE =
  '/registrations/details/:id/:enqID';

export const PRE_REGISTRATION_ECDAMVP_CHILD_PROFILE =
  '/ecda-registrations/details/:id/:enqID';
/** *  -------  ** */

/** * Classes - My Downloads - Parent Portal** */
export const MY_DOWNLOADS = '/mydownloads';
/** *  -------  ** */

/** * Parent Interaction - Staff Portal** */
export const PARENT_INTERACTION = '/parentInteraction';
export const PARENT_INTERACTION_DETAILS = '/parentInteraction/:id';
/** *  -------  ** */

/* ------- ------- ------------- ----------- ------------ --------- */

/* Non Authenticated routes */
export const HOMEPAGE = '/';
export const BENEFIT_OF_LINKPASS = '/benefitoflinkpass';
export const LP_SSO_CALLBACK = '/lp-sso-callback';
export const CONTACT_DETAILS_NOT_MATCH = '/contactdetailsnotmatch';
export const INTRO_PAGE = '/intro';
export const PARENT_INFO = '/parentinfo';
export const PARENT_SEARCH = '/parentsearch';
export const ENQUIRY = '/enquiry';
export const LOGIN = '/login';
export const CONNECT_TO_MFS_ACCOUNT = '/connecttomfsaccount';
export const REGISTER = '/register';
export const RESET = '/reset';
export const FORGOT = '/forgot';
export const NOT_FOUND = '/notfound';
export const SSO_RESPONSE = '/sso';
export const PARENT_ENQUIRY = '/parentenquiry';
export const CHAT = '/chat';
export const MAINTENANCE = '/maintenance';
export const PSS = '/pss/:id';
export const PSS_URL = '/pss';
export const APP_REDIRECT = '/app-redirect';

/* Centre Ops Reports */
export const CENTRE_OPS_REPORTS = '/centre-ops-reports';

/* Handbook dowload pdf */
export const PARENT_HANDBOOK = '/parent_handbook';
/* Whiteboard Care Journal */
export const CARE_JOURNAL_WHITEBOARD = '/whiteboard-care-journal';
/* Care Journal */
export const CARE_JOURNAL = '/care-journal';
