/* eslint-disable import/prefer-default-export */

import { get } from 'lodash';
import { AxiosHelper } from './axiosRequest';

/**
 * Get levels by teacher id
 * @param {*} teacherId
 * @returns
 */
export const getLevelsByTeacherId = async teacherId => {
  try {
    const res = await AxiosHelper({
      baseURL: process.env.REACT_APP_MS_CLASS_SVC_URL,
    }).get(`/class-teacher/get-class-and-level-by-teacher-id/${teacherId}`);
    return get(res, 'data.level.data') || [];
  } catch {
    return [];
  }
};
